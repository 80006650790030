import React from 'react';
import Route from './route';

function App() {
  return (
    <Route />
  );
}

export default App;
