import React from 'react';
import GameModal from '../../modals';
import seeds1 from '../../../assets/iDealTanam/seeds/seedskecil.png';
import seeds2 from '../../../assets/iDealTanam/seeds/seedssedang.png';
import seeds3 from '../../../assets/iDealTanam/seeds/seedsbesar.png';
import pohon1 from '../../../assets/iDealTanam/pohon/pohon1.png';
import pohon2 from '../../../assets/iDealTanam/pohon/pohon2.png';
import pohon3 from '../../../assets/iDealTanam/pohon/pohon3.png';
import WateringCan from '../wateringcan';

export default class Pohon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 50,
      coin : 0,
      openModal: false,
      pohon: pohon3
    };
  }

  growSeedsandTree() {
    this.setState({
      height: this.state.height + 10
    }, () => {
      if (this.state.height === 300) {
        this.setState({
          pohon: pohon3
        }, () => {
          this.setState({
            height: 400
          })
        })
      }
      this.props.updateChance()
    })
  }

  render() {

    return (
        <div style={{
          width: '100vw', height: '92vh', 
          textAlign: 'center'
          }}>
            {(this.state.height < 300) ?
              (
                <img 
                  onContextMenu={(e)=> {
                    e.preventDefault()
                    e.stopPropagation()
                    return false;
                  }}
                  style={{
                    display:'block',
                    position: 'absolute',
                    margin:'auto',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    marginBottom: '10vh',
                    height: `${(this.state.height >= 400 ? (200 / 6) : (this.state.height/6))}vh`,
                    PointerEvent: 'none'
                  }} 
                  ref={img => this.img = img}
                  src={
                    this.state.height < 70 ? seeds1 :
                    this.state.height < 100 ? seeds2 :
                    this.state.height < 200 ? seeds3 : 
                    this.state.height < 300 ? pohon2 : 
                    this.state.pohon
                  }
                />
              ) :
              (
                <img 
                  onContextMenu={(e)=> {
                    e.preventDefault()
                    e.stopPropagation()
                    return false;
                  }}
                  style={{
                    display:'block',
                    position: 'absolute',
                    margin:'auto',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    marginBottom: '10vh',
                    height: `${(this.state.height / 6)}vh`,
                    PointerEvent: 'none'
                  }} 
                  ref={img => this.img = img}
                  src={
                    this.state.pohon
                  }
                  onClick={
                    (e) => {
                      if (this.state.height >= 400) {
                        this.setState({
                          coin : this.state.coin + 10,
                          height : 50,
                          openModal: true
                        }, () => {
                          this.props.updateiDealPoints(this.state.coin)
                        })
                      }
                  }}
                />
              )
            }
            <WateringCan 
              height={this.state.height} 
              growing={this.growSeedsandTree.bind(this)}
              chance={this.props.chance}
            />
            <GameModal isOpen={this.state.openModal}
            okClick={() => {
              this.setState({openModal: !this.state.openModal
            })}} 
            toggle={() => {
                this.setState({openModal: !this.state.openModal
            })}}
            title={"Kamu mendapatkan iDeal Points"}
            message={`Yeay! pohon mu membuahkan iDeal points sebanyak ${this.state.coin}`}
            />
        </div>
    );
  }
}
