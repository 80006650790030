import React, {useState} from 'react';
import Wheel from '../../../components/wheel';
import {
    Container
} from 'reactstrap';

function RouletteGames() {
    const data = [
        '10', '20', '30', '40', '50', '60', '70', '80'
    ];
    const [selected, setSelected] = useState(Math.floor(Math.random() * data.length))
    return (
        <Container>
            <input
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
                type="number"
                onChange={(e) => {
                    let number = Number(e.currentTarget.value)
                    if (Number(e.currentTarget.value) > data.length) {
                        number = data.length
                    }
                    if (Number(e.currentTarget.value) <= 0) {
                        number = 1
                    }
                    setSelected(number)
                }}
            />
            <Wheel
                items={data}
                selected={selected - 1}
            />
        </Container>
    );
}

export default RouletteGames;
