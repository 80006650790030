import React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by freepik - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by 0melapics - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by brgfx - www.freepik.com</a> */}

export default class GameModal extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Modal
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
        >
            {/* <ModalHeader></ModalHeader> */}
            <ModalBody>
                <h5>{this.props.title}</h5>
                {this.props.message}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.props.okClick} >OK</Button>
            </ModalFooter>
        </Modal>
    );
  }
}
