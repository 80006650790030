import React from 'react';
import rouletteGames from './pages/games/rouletteGames/rouletteGames';
import iDealTanamGames from './pages/games/iDealTanamGames/iDealTanamGames';
import iDealFarmingGames from './pages/games/iDealFramingGames/iDealFarmingGames';
import iDealCrushGames from './pages/games/iDealCrushGames/iDealCrushGames';
import HomePages from './pages/homepages/HomePages';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export default function RouteComponent() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" name="Homepage" component={HomePages} />  
        <Route exact path="/idealroulette" name="Roulette" component={rouletteGames} />
        <Route exact path="/idealtanam" name="iDeal Tanam" component={iDealTanamGames} />
        <Route exact path="/idealfarming" name="iDeal Farming" component={iDealFarmingGames} />
        <Route exact path="/idealcrush" name="iDeal Crush" component={iDealCrushGames} />
      </Switch>
    </BrowserRouter>
  );
}