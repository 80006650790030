import React, {useEffect, useState} from 'react';
import ScoreBoard from './components/ScoreBoard';
import blueCandy from './images/blue-candy.png';
import greenCandy from './images/green-candy.png';
import orangeCandy from './images/orange-candy.png';
import purpleCandy from './images/purple-candy.png';
import redCandy from './images/red-candy.png';
import yellowCandy from './images/yellow-candy.png';
import blank from './images/blank.png';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const width = 8
const candyColors = [
    blueCandy,
    orangeCandy,
    purpleCandy,
    redCandy,
    yellowCandy,
    greenCandy
]

function CrushGames () {
    require('./css/index.css');
    const [currentColorArrangement, setCurrentColorArrangement] = useState([])
    const [squareBeingDragged, setSquareBeingDragged] = useState(null)
    const [squareBeingReplaced, setSquareBeingReplaced] = useState(null)
    const [scoreDisplay, setScoreDisplay] = useState(0)

    const checkForColumnOfFour = () => {
        for (let i = 0; i <= 39; i++) {
            const columnOfFour = [i, i + width, i + width * 2, i + width * 3]
            const decidedColor = currentColorArrangement[i]
            const isBlank = currentColorArrangement[i] === blank

            if (columnOfFour.every(square => currentColorArrangement[square] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 4)
                columnOfFour.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForRowOfFour = () => {
        for (let i = 0; i < 64; i++) {
            const rowOfFour = [i, i + 1, i + 2, i + 3]
            const decidedColor = currentColorArrangement[i]
            const notValid = [5, 6, 7, 13, 14, 15, 21, 22, 23, 29, 30, 31, 37, 38, 39, 45, 46, 47, 53, 54, 55, 62, 63, 64]
            const isBlank = currentColorArrangement[i] === blank

            if (notValid.includes(i)) continue

            if (rowOfFour.every(square => currentColorArrangement[square] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 4)
                rowOfFour.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForColumnOfThree = () => {
        for (let i = 0; i <= 47; i++) {
            const columnOfThree = [i, i + width, i + width * 2]
            const decidedColor = currentColorArrangement[i]
            const isBlank = currentColorArrangement[i] === blank

            if (columnOfThree.every(square => currentColorArrangement[square] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 3)
                columnOfThree.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const checkForRowOfThree = () => {
        for (let i = 0; i < 64; i++) {
            const rowOfThree = [i, i + 1, i + 2]
            const decidedColor = currentColorArrangement[i]
            const notValid = [6, 7, 14, 15, 22, 23, 30, 31, 38, 39, 46, 47, 54, 55, 63, 64]
            const isBlank = currentColorArrangement[i] === blank

            if (notValid.includes(i)) continue

            if (rowOfThree.every(square => currentColorArrangement[square] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 3)
                rowOfThree.forEach(square => currentColorArrangement[square] = blank)
                return true
            }
        }
    }

    const moveIntoSquareBelow = () => {
        for (let i = 0; i <= 55; i++) {
            const firstRow = [0, 1, 2, 3, 4, 5, 6, 7]
            const isFirstRow = firstRow.includes(i)

            if (isFirstRow && currentColorArrangement[i] === blank) {
                let randomNumber = Math.floor(Math.random() * candyColors.length)
                currentColorArrangement[i] = candyColors[randomNumber]
            }

            if ((currentColorArrangement[i + width]) === blank) {
                currentColorArrangement[i + width] = currentColorArrangement[i]
                currentColorArrangement[i] = blank
            }
        }
    }

    const dragStart = (e) => {
        console.log(e)
        // setSquareBeingDragged(e.target)
        setSquareBeingDragged(e)
    }
    const dragDrop = (e) => {
        console.log(e)
        // setSquareBeingReplaced(e.target)
        setSquareBeingReplaced(e.dropData)
    }
    const dragEnd = () => {
        // const squareBeingDraggedId = parseInt(squareBeingDragged.getAttribute('data-id'))
        // const squareBeingReplacedId = parseInt(squareBeingReplaced.getAttribute('data-id'))
        const squareBeingDraggedId = parseInt(squareBeingDragged['data-id'])
        const squareBeingReplacedId = parseInt(squareBeingReplaced['data-id'])
        console.log(squareBeingDraggedId, squareBeingReplacedId)

        const validMoves = [
            squareBeingDraggedId - 1,
            squareBeingDraggedId - width,
            squareBeingDraggedId + 1,
            squareBeingDraggedId + width
        ]

        const validMove = validMoves.includes(squareBeingReplacedId)

        console.log(validMoves, validMove)

        if (validMove) {
            currentColorArrangement[squareBeingReplacedId] = squareBeingDragged['src']
            currentColorArrangement[squareBeingDraggedId] = squareBeingReplaced['src']
            const isAColumnOfFour = checkForColumnOfFour()
            const isARowOfFour = checkForRowOfFour()
            const isAColumnOfThree = checkForColumnOfThree()
            const isARowOfThree = checkForRowOfThree()
            if (squareBeingReplacedId &&
                (isARowOfThree || isARowOfFour || isAColumnOfFour || isAColumnOfThree)) {
                setSquareBeingDragged(null)
                setSquareBeingReplaced(null)
            } else {
                currentColorArrangement[squareBeingReplacedId] = squareBeingReplaced['src']
                currentColorArrangement[squareBeingDraggedId] = squareBeingDragged['src']
            }
        }

    }


    const createBoard = () => {
        const randomColorArrangement = []
        for (let i = 0; i < width * width; i++) {
            const randomColor = candyColors[Math.floor(Math.random() * candyColors.length)]
            randomColorArrangement.push(randomColor)
        }
        setCurrentColorArrangement(randomColorArrangement)
    }

    useEffect(() => {
        createBoard()
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            checkForColumnOfFour()
            checkForRowOfFour()
            checkForColumnOfThree()
            checkForRowOfThree()
            moveIntoSquareBelow()
            setCurrentColorArrangement([...currentColorArrangement])
        }, 100)
        return () => clearInterval(timer)
    }, [checkForColumnOfFour, checkForRowOfFour, checkForColumnOfThree, checkForRowOfThree, moveIntoSquareBelow, currentColorArrangement])


    return (
        <div className="app">
                <div className="game">
                    {currentColorArrangement.map((candyColor, index) => (
                        <DropTarget
                            dropData={{
                                "key": index,
                                "data-id" : index,
                                "src": candyColor
                            }}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragLeave={(e) => e.preventDefault()}
                        >
                            <DragDropContainer 
                                dragData={{
                                    "key": index,
                                    "data-id" : index,
                                    "src": candyColor
                                }}
                                onDragStart={dragStart}
                                onDragEnd={dragEnd}
                                onDrop={dragDrop}
                            >
                                <img
                                    key={index}
                                    src={candyColor}
                                    alt={candyColor}
                                    data-id={index}
                                />
                            </DragDropContainer>
                        </DropTarget>
                    ))}
                </div>
            {/* <div className="game">
                {currentColorArrangement.map((candyColor, index) => (
                    <img
                        key={index}
                        src={candyColor}
                        alt={candyColor}
                        data-id={index}
                        draggable={true}
                        onDragStart={dragStart}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => e.preventDefault()}
                        onDragLeave={(e) => e.preventDefault()}
                        onDragEnd={dragEnd}
                        onDrop={dragDrop}
                    />
                ))}
            </div> */}
            <ScoreBoard score={scoreDisplay}/>
        </div>
    )
}

export default CrushGames;