import React from 'react';
import Pohon from '../pohon';
import StatusBar from '../../statusbar';
import tamanbackground from '../../../assets/iDealTanam/32159_lower.jpg';
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by freepik - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by 0melapics - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by brgfx - www.freepik.com</a> */}

export default class Background extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      iDealPoints: 0,
      chance: 30
    };
  }

  updateChance () {
    this.setState({
      chance: this.state.chance - 1
    })
  }

  updateiDealPoints (points) {
    this.setState({
      iDealPoints: points
    })
  }

  render() {
    return (
        <div style={{backgroundImage: `url(${tamanbackground})`,backgroundSize: 'cover', width: '100vw', height: '100vh'}}>
            <StatusBar chance={`${this.state.chance} Air`} idealpoints={this.state.iDealPoints}/>
            <Pohon 
              chance={this.state.chance} 
              updateChance={this.updateChance.bind(this)}
              updateiDealPoints={this.updateiDealPoints.bind(this)}
            />
        </div>
    );
  }
}
