import React from 'react';
import tilesBG from '../../../assets/iDealFarming/tiles/soil_tile.png';
import Seeds from '../seeds';

export default class Tiles extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div style={{backgroundImage: `url(${tilesBG})`,backgroundSize: 'contain', 
            backgroundRepeat: 'no-repeat', width: '100%', height: '100%', 
            display:'flex', textAlign: 'center', alignItems: 'center'}}>
            {/* <div style={{width: '8vh', height: '8vh'}}>
                <Seeds/>
            </div> */}
        </div>
    );
  }
}
