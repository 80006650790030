import React from 'react';
import Grass from './grass';

export default class IDealFarming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  render() {
    return (
      <Grass/>
    );
  }
}
