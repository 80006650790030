import React from 'react';
import GameModal from '../../modals';
import wateringcan from '../../../assets/iDealTanam/wateringcan.png';
import wateringcanleft from '../../../assets/iDealTanam/wateringcanleft.png';
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by freepik - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by 0melapics - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by brgfx - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/vegetables'>Vegetables vector created by freepik - www.freepik.com</a> */}

export default class WateringCan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waterLeft: this.props.chance,
      showWatering: false,
      wateringcanposition: 20,
      loop: 0,
      openModal: false
    };
  }

  watering () {
    if (this.state.waterLeft < 1) { this.setState({openModal: true, title: "Air kamu telah habis", message: "Tunggu beberapa saat lagi untuk air mu terisi penuh"}); return; }
    if (this.props.height === 400) { this.setState({openModal: true, title: "Pohon kamu sudah bisa di tebang", message: "Usaha mu membuahkan hasil ! Pohon mu sudah bisa ditebang"}); return; }
    this.setState({
        waterLeft : this.state.waterLeft - 1,
        showWatering: true
    }, () => {
        this.wateringanimation();
        console.log(this.state.waterLeft)
    })
  }

  wateringanimation () {
    setTimeout(function() {
        this.setState({
            wateringcanposition: (this.state.wateringcanposition === 20) ? 30 : 20,
            loop: this.state.loop + 1
        }, () => {
            if (this.state.loop < 5) {
                this.wateringanimation();
            } else {
                this.setState({
                    showWatering: false,
                    wateringcanposition: 20,
                    loop: 0
                }, () => {
                    this.props.growing()
                })
            }
        })
    }.bind(this), 400)   
  }

  render() {
    return (
        <div style={{
            width: '100vw', height: '92vh', 
            textAlign: 'center'
            }}>
            {this.state.showWatering && 
                (
                    <img 
                        onContextMenu={(e)=> e.preventDefault()}
                        style={{
                            display:'block',
                            position: 'absolute',
                            margin:'auto',
                            left: 100,
                            right: 0,
                            bottom: 0,
                            marginBottom: ( this.props.height * 1.5) + 10,
                            height: 50,
                            transform: `rotate(-${this.state.wateringcanposition}deg)`,
                            transformOrigin:'left top'
                        }} src={wateringcanleft}/>
                )
            }
            {!this.state.showWatering && 
                (
                    <img 
                        onContextMenu={(e)=> e.preventDefault()}
                        style={{
                            display:'block',
                            position: 'absolute',
                            margin:'auto',
                            left: 200,
                            right: 0,
                            bottom: 0,
                            marginBottom: 10,
                            height: 100,
                        }} onClick={(e) => this.watering(e)} src={wateringcan}/>
                )
            }
            <GameModal 
                isOpen={this.state.openModal} 
                okClick={() => {
                    this.setState({openModal: !this.state.openModal
                })}} 
                toggle={() => {
                    this.setState({openModal: !this.state.openModal
                })}}
                title={this.state.title}
                message={this.state.message}
            />
          </div>
    );
  }
}
