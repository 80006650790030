import React from 'react';
import Background from './background';

export default class IDealTanam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  render() {
    return (
      <Background/>
    );
  }
}
