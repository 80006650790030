import React from 'react';
import {
    Card,
    CardHeader
} from 'reactstrap';

function HomePages() {
    return(
        <Card>
            <CardHeader onClick={(e) => {window.location.href= "/idealroulette"}}>
                Roulette
            </CardHeader>
            <CardHeader onClick={(e) => {window.location.href= "/idealtanam"}}>
                iDeal Tanam
            </CardHeader>
            <CardHeader onClick={(e) => {window.location.href= "/idealfarming"}}>
                iDeal Farming
            </CardHeader>
            <CardHeader onClick={(e) => {window.location.href= "/idealcrush"}}>
                iDeal Crush
            </CardHeader>
        </Card>
    )
}

export default HomePages;