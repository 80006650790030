import React from 'react';
import tileswithseed from '../../../assets/iDealFarming/seeds/tileswithseed.png';

export default class Seeds extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div style={{backgroundImage: `url(${tileswithseed})`,backgroundSize: 'cover', width: '100%', height: '100%'}}>
        </div>
    );
  }
}
