import React from 'react';
import {
    Navbar,
} from 'reactstrap';
import backgroundpapan from '../assets/iDealTanam/2551079_lower.jpg';  
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by freepik - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by 0melapics - www.freepik.com</a> */}
{/* <a href='https://www.freepik.com/vectors/tree'>Tree vector created by brgfx - www.freepik.com</a> */}

export default class StatusBar extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Navbar style={{height: '8vh', color :'white',  backgroundColor: 'rgba(254, 91, 38, 0.4)', backgroundImage: `url(${backgroundpapan})`}}>
            <div style={{color: 'black', fontWeight: 'bold'}}>{this.props.chance}</div>
            <div style={{color: 'black', fontWeight: 'bold'}}>{this.props.idealpoints} Poin iDeal</div>
        </Navbar>
    );
  }
}
