import React from 'react';
import grass from '../../../assets/iDealFarming/tiles/grass_tile.jpg';
import {Row, Col} from 'reactstrap';
import Tiles from '../tiles';

export default class Grass extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const listtiles = [1,2,3,4,5,6,7,8,9]
    return (
        <div style={{backgroundImage: `url(${grass})`,backgroundSize: 'cover', width: '100vw', height: '100vh', 
            display:'flex',textAlign: 'center', alignItems: 'center'}}>
            <Col>
                <Row>
                    {listtiles.map( value => {
                        return (
                            <Col xs="4" md="4" lg="4" style={{marginTop: 20, display:'flex', textAlign: 'center', alignItems: 'center'}}>
                                <div style={{width: '20vh', height: '10vh', textAlign: 'center', alignItems: 'center'}}>
                                    <Tiles/>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
        </div>
    );
  }
}
