import React from 'react';
import IDealFarming from '../../../components/idealfarming';

function IDealFarmingGames() {
    return (
        <IDealFarming />
    );
}

export default IDealFarmingGames;
