import React from 'react';
import IDealTanam from '../../../components/idealtanam';

function IDealTanamGames() {
    return (
        <IDealTanam />
    );
}

export default IDealTanamGames;
